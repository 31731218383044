@import '@angular/cdk/overlay-prebuilt.css';

.product-info .mat-dialog-container {
    padding: 0;
}

@font-face {
    font-family: BrandonGrotesque;
    src: url('./assets/fonts/BrandonGrotesque-Regular.otf') format('opentype');
}

@font-face {
    font-family: Josefin Sans;
    src: url('./assets/fonts/JosefinSans-Regular.ttf');
}

@font-face {
    font-family: lora-bold;
    src: url('./assets/fonts/Lora-Regular.ttf');
}

.pu_container {
    width: 1170px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.p-10 {
    padding: 10px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.text-center {
    text-align: center;
}

.font-retail {
    font-size: 14px;
}

@media screen and (max-width: 992px) {
    .pu_container {
        width: calc(100% - 30px);
    }
}

@media screen and (max-width: 768px) {
    .pu_container {
        width: calc(100% - 30px);
    }
}

.text-uppercase {
    text-transform: uppercase;
}

.mat-option {
    white-space: normal !important;
    overflow: hidden;
    display: block;
    line-height: 20px !important;
    height: 96px !important;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    border: 1px solid #00614538;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}
body #accessiblyAppWidgetButton.accessibly-app-widget-button-container {
    position: fixed;
    right: 30px !important;
}